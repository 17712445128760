'use client'

import { useEffect, useState } from 'react'
import { usePathname, useRouter } from 'next/navigation'

import SearchForm from '../zoeken/_components/SearchForm'

type Props = {
  className?: string
}

export default function SearchFormRedirect({ className }: Props) {
  const [searchTerm, setSearchTerm] = useState('')
  const router = useRouter()
  const pathname = usePathname()
  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    setLoading(true)
    // Encode the search term to safely include it in the URL
    const newSearchParams = new URLSearchParams()
    newSearchParams.set('q', searchTerm)
    // Redirect to the desired page with the search term as a query parameter
    router.push(`/zoeken?${newSearchParams.toString()}`)
  }

  useEffect(() => {
    setLoading(false)
  }, [pathname])

  return (
    <SearchForm
      className={className}
      setSearchTerm={setSearchTerm}
      searchTerm={searchTerm}
      onSubmit={handleSubmit}
      loading={loading}
    />
  )
}
