import type { Dispatch, SetStateAction } from 'react'

import { Button, Icon, iconSearch, Spinner } from '@eo/next-ui'
import clsx from 'clsx/lite'

type Props = React.ComponentProps<'form'> & {
  setSearchTerm: Dispatch<SetStateAction<string>>
  searchTerm: string
  loading?: boolean
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void
}

export default function SearchForm({
  setSearchTerm,
  searchTerm,
  loading,
  onSubmit,
  className,
  ...formProps
}: Props) {
  return (
    <form
      className={clsx('relative flex', className)}
      {...formProps}
      onSubmit={(e) => {
        e.preventDefault()
        if (onSubmit) {
          onSubmit(e)
        }
      }}
    >
      <input
        onChange={(e) => setSearchTerm(e.target.value)}
        className="h-full max-w-full grow rounded-lg border border-purple-700 bg-white p-4 text-gray-900 caret-purple-700 outline-none placeholder:text-lg placeholder:text-gray-400"
        placeholder="Waar ben je naar op zoek?"
        required
        type="search"
        value={searchTerm}
      />
      <Button
        className="absolute right-0 aspect-square h-full !rounded-l-none !rounded-r-lg !border-none [background:linear-gradient(265.8deg,_#6A27FF_-11.65%,_#81009A_89.73%)]"
        type="submit"
        textColor="white"
        label="Zoek op je zoekopdracht"
      >
        {loading && (
          <Spinner
            color="white"
            compact
            size="sm"
          />
        )}
        {!loading && (
          <Icon
            icon={iconSearch}
            size={20}
          />
        )}
      </Button>
    </form>
  )
}
